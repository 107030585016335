import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SurveyFormComponent } from './surveyform/surveyform.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { SurveyFormService } from './services/surveyform.services';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, HttpClientModule, NgxMaskModule.forRoot()],
  declarations: [AppComponent, SurveyFormComponent],
  providers: [SurveyFormService],
  bootstrap: [AppComponent]
})
export class AppModule { }
