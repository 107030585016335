export enum TYPE {
  TEXT = 'text',
  EMAIL = 'email',
  DATE = 'date',
  SELECT = 'select',
  PASSWORD = 'password',
  NUMBER = 'number',
  RADIO = 'radio',
  RADIO_IMAGE = 'radio_image',
  CHECKBOX = 'checkbox',
  DINAMIC = 'dinamic',
  MULTIPLES_INPUTS = 'multiples_inputs'
}

export enum MASK {
  TEXT = 'text',
  EMAIL = 'email',
  DATE = 'date',
  PHONE = 'phone'
}

export interface Survey {
  label: string;
  english?: boolean;
  idForm?: number;
  formName?: string;
  questions: Question[];
  style?: {
    background: string;
    color: string;
  };
}

interface Question {
  name: string;
  nameSecudary?: string;
  disabled?: boolean;
  placeHolder?: string;
  label: string;
  descricao?: string;
  type: TYPE;
  required: boolean;
  value?: any;
  valueSecudary?: any;
  answerIndex?: any;
  labelFirst?: any;
  tooltip?: any;
  descriptionMini?: any;
  options?: { // for select or multiple choice type
    name?: string;
    mask?: MASK;
    placeHolder?: string;
    required?: boolean;
    tooltip?: any;
    value?: any,
    label?: string,
    icon?: string,
    question?: Question;
  }[];
}