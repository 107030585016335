import { Component, Input, Inject } from '@angular/core';
import { SurveyFormService } from './services/surveyform.services';
import { MASK, Survey, TYPE } from './surveyform/model';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  subject: string = '';
  message: string = '';
  loading: boolean = false;

  constructor(private surveyService: SurveyFormService) {
  }

  sendEmail(surveys: any[]) {
    let answer: string = '';
    this.loading = true;
    let idFormulario;
    let formName;
    let nome;
    let email;

    surveys.forEach(survey => {
      idFormulario = survey.item._data.idForm;
      formName = survey.item._data.formName;
      
      survey.item._data.questions.forEach(question => {
        if (question.name == 'nome_form') {nome = question.value;}
        if (question.name == 'email_form') {email = question.value;}

        if (question.options != undefined) {
          let nome = '';
          let nameSecudary = '';
          if (question.answerIndex != null) {
            let labelFirst = question.labelFirst != undefined ? question.labelFirst : '';
            nome =  labelFirst + '' + survey.item._data.questions[question.answerIndex].value;
          }
          if (question.nameSecudary != null && question.options[question.value] != undefined && question.options[question.value].placeHolder != undefined) {
            nameSecudary = ' [ ' + survey.item.fg.controls[question.nameSecudary].value + ' ]';
          }

          if (question.type == 'multiples_inputs') {
            answer += nome + question.label + ': ';
            var i;
            for (i = 0; i < question.options.length; i++) {
              answer += " [ " + question.options[i].placeHolder + " : "+ question.value[i] +" ] ";
            }
          } else {
            answer += nome + question.label + ': ' + (question.options.length == 0 ? question.label : question.options[question.value].label) + nameSecudary;
          }

          answer += '\n';
        } else {
          answer += question.label + ': '+ question.value;
          answer += '\n';
        }
      });
    });
    // console.log(answer);
    this.subject = "Form 2 [preenchido]";
    this.message = answer;

    this.surveyService.leadToMautic(idFormulario, formName, nome, email).subscribe(() => {
      console.log('Sucess lead to Mautic');
    });

    this.surveyService.save(this.subject, this.message).subscribe(() => {
      console.log('E-mail enviado com sucesso');
      window.location.href = 'https://beonne.com/form_2enviado/'; // formulario 2
    });
  }

  voltarInicio(dados: any, index: number) {
    dados.active = index;
  }

  removeItens(dados: any, indexs: any[], isRemove: boolean) {
    if (isRemove) {
      indexs.forEach(item => {
        if(dados.questions[5].name == item.name) {
          dados.questions.splice(item.index, 1);
        }
      })
    }
  }

// Inicio - Formulario 2

dataForm2: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 1',
  questions: [
    {
      name:'nome_form',
      placeHolder: 'Nome',
      label: 'Antes de prosseguirmos, qual o seu primeiro nome?',
      tooltip: 'Olá, muito bem vindo, eu sou a Patricia e vou guiar você para a sua melhor experiência ;)',
      type: TYPE.TEXT,
      required: true
    },
    {
      name:'question12radio',
      labelFirst: 'Olá ',
      label: ', O seu produto já está listado na Amazon EUA?',
      answerIndex: 0,
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: 'Sim'
        },
        {
          value: 1,
          label: 'Não'
        }
      ]
    }
  ]
}

// Escolheu estados unidos na pergunta: Olá Fernando, O seu produto já está listado na Amazon EUA?
dataForm2EUA: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 2',
  questions: [
    {
      /* Pergunta 1 */
      name:'question3radio',
      answerIndex: 0,
      label: ', considerando a sua disponibilidade de tempo agora, selecione uma das opções abaixo:',
      descricao: '',
      type: TYPE.RADIO,
      tooltip: 'Sugerimos que selecione a versão completa do nosso formulário de atendimento.',
      required: true,
      options: [
        {
          value: 0,
          label: 'Tenho 40 segundos e vou preencher o formulário completo porque entendo o tamanho e importância da Amazon para o meu produto.'
        },
        {
          value: 1,
          label: 'Tenho apenas 20 segundos e quero preencher o formulário rápido porque entendo que a Amazon não é tão importante para o meu negócio.'
        }
      ]
    },
    {
     /* Pergunta 2 */
      name:'question5radio',
      nameSecudary: 'question4text',
      label: 'Sobre a sua marca',
      // descricao: '',
      type: TYPE.MULTIPLES_INPUTS,
      required: false,
      options: [
        {
          value: '',
          required: false,
          tooltip: 'Fique tranquilo, nós não compartilhamos nenhuma destas informações.',
          placeHolder: 'Qual o nome da sua marca?'
        }
      ]
    },
    {
    /* Pergunta 51 */
    name:'questionTextDescption',
     placeHolder: 'Descrição do produto',
      label: 'Descreva o seu produto em poucas palavras ou informe o numero ASIN caso você saiba',
      tooltip: 'Fique tranquilo, as informações do seu produto estão seguras e não serão divulgadas',
      type: TYPE.TEXT,
      required: true
    },
    {
      /* Pergunta 4 */
      name:'question7radio',
      nameSecudary: 'question7text',
      label: 'Em qual segmento seu produto se encaixa?',
      // descricao: 'Em qual segmento o seu produto se encaixa?',
      tooltip: 'Na dúvida, fique a vontade para especificar em qual categoria você enquadra o seu produto',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Beleza & cuidados pessoais'
        },
        {
          value: 1,
          label: 'Vitaminas & suplementos'
        },
        {
          value: 2,
          label: 'Acessórios para atividades esportivas'
        },
        {
          value: 3,
          label: 'Casa e cozinha',
        },
        {
          value: 4,
          label: 'Alimentos em geral',
        },
        {
          value: 5,
          placeHolder: 'Outros',
          required: true,
          label: 'Outros (especifique abaixo)',
        }
      ]
    },
    {
      /* Pergunta 5 */
      name:'question8radio',
      // nameSecudary: 'question8text',
      label: 'Itens para suporte',
      descricao: 'Quantos itens você precisa de suporte?',
      tooltip: 'Selecione a sua variedade de produtos',
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: '1'
        },
        {
          value: 1,
          label: '2'
        },
        {
          value: 2,
          label: '3'
        },
        {
          value: 3,
          label: '4 ou mais',
        }
      ]
    },
    {
      /* Pergunta 6 */
      name:'question9radio',
      label: 'Canais de venda do seu produto',
      descricao: 'Onde mais você vende este produto?',
      tooltip: 'Fique a vontade para marcar quantas opções quiser',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Não vendo em outros canais'
        },
        {
          value: 1,
          label: 'Vendo em loja física'
        },
        {
          value: 2,
          label: 'Vendo no meu site'
        },
        {
          value: 3,
          label: 'Vendo nas redes sociais'
        },
        {
          value: 4,
          label: 'Vendo em outros marketplaces'
        },
        {
          value: 5,
          label: 'Em outros canais',
        }
      ]
    },
    {
      /* Pergunta 7 */
      name:'question10radio',
      label: 'Há quanto tempo você vende este produto na Amazon?',
      // descricao: '',
      tooltip: 'Fique tranquilo, suas informações não serão divulgadas.',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Menos de 6 meses'
        },
        {
          value: 1,
          label: 'Entre 6 meses e 1 ano'
        },
        {
          value: 2,
          label: 'Entre 1 ano e 3 anos'
        },
        {
          value: 3,
          label: 'Mais de 3 anos',
        }
      ]
    },
    {
      /* Pergunta 8 */
      name:'question11radio',
      label: 'Vendas na Amazon',
      descricao: 'Qual o seu faturamento mensal na Amazon?',
      tooltip: 'Esses dados são usados como fonte de pesquisa interna e não são divulgados',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: '0 até $1 000 por mês'
        },
        {
          value: 1,
          label: '$1 000 até $5 000 por mês'
        },
        {
          value: 2,
          label: '$5 000 até $20 000 por mês'
        },
        {
          value: 3,
          label: '$20 000 até $50 000 por mês',
        },
        {
          value: 4,
          label: 'Acima de $50 000 por mês',
        }
      ]
    },
    {
      /* Pergunta 51 nova */
      name:'question57radio',
      // nameSecudary: 'question51text',
      label: 'Investimento na Amazon',
      descricao: 'Quanto você pensa em investir para alavancar o seu produto na Amazon neste momento?',
      tooltip: 'Quanto maior o seu investimento na plataforma, maiores as suas possibilidades de retorno',
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: 'Acima de $10 000 por mês'
        },
        {
          value: 1,
          label: 'Entre $6 000 a $10 000 por mês'
        },
        {
          value: 2,
          label: 'Entre $4 000 a $6 000 por mês'
        },
        {
          value: 3,
          label: 'Entre $2 000 a $4 000 por mês'
        },
        {
          value: 4,
          label: 'Abaixo de $2 000 por mês',
        }
      ]
    },
    {
      /* Pergunta 10 */ 
      name:'question15text',
      placeHolder: 'Pergunta final',
      label: 'Em poucas linhas, descreva qual tipo de ajuda você está buscando?',
      tooltip: 'Use esse espaço para dizer como podemos te ajudar !',
      type: TYPE.TEXT,
      required: true
    },
    {
      name:'email_form',
      placeHolder: 'Digite seu melhor email',
      label: 'Preencha seus dados de contato e clique no botão ENVIAR !',
      tooltip: 'Obrigado ! Não esqueça de clicar em enviar formulário para que possamos entrar em contato com você.',
      type: TYPE.TEXT,
      required: true,
    }
  ]
}

// Não escolheu estados unidos na pergunta: Olá Fernando, O seu produto já está listado na Amazon EUA?
dataForm2ProdutoEstocado: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 1',
  questions: [
    {
      name:'questionProdutoEstoqueRadio',
      nameSecudary: 'question3radioCampoOutros',
      labelFirst: 'Olá ',
      label: ', selecione o país onde o seu produto está estocado nesse momento',
      tooltip: ', nós precisamos dessa informação para tratar do seu produto da melhor forma e menor custo',
      answerIndex: 0,
      type: TYPE.RADIO,
      // type: TYPE.RADIO_IMAGE,
      required: true,
      options: [
        {
          value: 0,
          label: 'Estados Unidos',
          // icon: 'https://www.miracooldigital.com/wp-content/uploads/2021/05/united-states.png'
        },
        {
          value: 1,
          label: 'Brasil',
          // icon: 'https://www.miracooldigital.com/wp-content/uploads/2021/05/brazil.png'
        },
        {
          value: 2,
          placeHolder: 'Outro',
          required: true,
          label: 'Outro (especifique)',
          // icon: 'https://www.miracooldigital.com/wp-content/uploads/2021/05/indonesia.png'
        }
      ]
    },
  ]
}

dataForm2NaoEUA: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 1',
  questions: [
    {
      /* Pergunta 1 */
      name:'question3radio',
      answerIndex: 0,
      label: ', considerando a sua disponibilidade de tempo agora, selecione uma das opções abaixo:',
      descricao: '',
      type: TYPE.RADIO,
      tooltip: 'Sugerimos que selecione a versão completa do nosso formulário de atendimento.',
      required: true,
      options: [
        {
          value: 0,
          label: 'Tenho 40 segundos e vou preencher o formulário completo porque entendo o tamanho e importância da Amazon para o meu produto.'
        },
        {
          value: 1,
          label: 'Tenho apenas 20 segundos e quero preencher o formulário rápido porque a Amazon não é prioridade para o meu negócio.'
        }
      ]
    },
    {
     /* Pergunta 2 */
      name:'question5radio',
      nameSecudary: 'question4text',
      label: 'Sobre a sua marca',
      // descricao: '',
      type: TYPE.MULTIPLES_INPUTS,
      required: false,
      options: [
        {
          value: '',
          required: false,
          tooltip: 'Fique tranquilo, nós não compartilhamos nenhuma destas informações.',
          placeHolder: 'Qual o nome da sua marca?'
        }
      ]
    },
    {
    /* Pergunta 51 */
      name:'question3TextDescrition',
      placeHolder: 'Descrição do produto',
      label: 'Descreva o seu produto em poucas palavras',
      tooltip: 'Fique tranquilo, as informações do seu produto estão seguras e não serão divulgadas',
      type: TYPE.TEXT,
      required: true
    },
    {
     /* Pergunta 4 */
      name:'question7radio',
      nameSecudary: 'question7text',
      label: 'Em qual segmento seu produto se encaixa?',
      descricao: '',
      tooltip: 'Na dúvida, fique a vontade para especificar em qual categoria você enquadra o seu produto',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Beleza & cuidados pessoais'
        },
        {
          value: 1,
          label: 'Vitaminas & suplementos'
        },
        {
          value: 2,
          label: 'Acessórios para atividades esportivas'
        },
        {
          value: 3,
          label: 'Casa e cozinha',
        },
        {
          value: 4,
          label: 'Alimentos em geral',
        },
        {
          value: 5,
          placeHolder: 'Outros',
          required: true,
          label: 'Outros (especifique abaixo)',
        }
      ]
    },
    {
        /* Pergunta 5 */
      name:'question8radio',
      // nameSecudary: 'question8text',
      label: 'Quantidade de produtos',
      descricao: 'Quantos produtos você deseja listar na Amazon EUA?',
      tooltip: 'Esta informação é importante para sabermos da variedade do seu produto',
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: '1'
        },
        {
          value: 1,
          label: '2'
        },
        {
          value: 2,
          label: '3'
        },
        {
          value: 3,
          label: '4 ou mais',
        }
      ]
    },
    {
      /* Pergunta 6 */
      name:'question9radio',
      label: 'Canal de vendas atual',
      descricao: 'Qual das opções abaixo se aplica ao seu produto neste momento?',
      tooltip: 'Fique a vontade para marcar quantas opções quiser',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Não vendo ainda'
        },
        {
          value: 1,
          label: 'Vendo em loja fisica'
        },
        {
          value: 2,
          label: 'Vendo no meu site'
        },
        {
          value: 3,
          label: 'Vende em redes sociais'
        },
        {
          value: 4,
          label: 'Vendo em outros marketplaces'
 },
        {
          value: 5,
          label: 'Vendo em outros outros canais',
        }
      ]
    },
  ]
}

dataForma2NaoEUSubFluxo1: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 2',
  questions: [
    {
      /* Pergunta 9 */ 
      name:'question13radio',
      label: 'Disponibilidade do se produto',
      descricao: 'Quando você gostaria de introduzir o seu produto na Amazon?',
      tooltip: 'Cerca de 15% dos nossos clientes começaram a vender na Amazon sem ter idéia alguma de por começar. Se este for o seu caso, fique tranquilo, nós vamos te ajudar :)',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Imediato, já estamos pronto para vender'
        },
        {
          value: 1,
          label: 'Em até 30 dias'
        },
        {
          value: 2,
          label: 'Acima de 30 dias, pois temos decisões a tomar'
        },
        {
          value: 3,
          label: 'Ainda não sei',
        }
      ]
    },
    {
      /* Pergunta 51 nova */
      name:'question51radio4',
      // nameSecudary: 'question51text',
      label: 'Investimento na Amazon',
      descricao: 'Quanto você pretende investir (em dólares) na Amazon?',
      tooltip: 'Quanto maior o seu investimento na plataforma, maiores as suas chances de retorno',
      type: TYPE.RADIO,
      required: false,
      options: [
          {
            value: 0,
            label: 'Acima de $10 000 por mês'
          },
          {
            value: 1,
            label: 'Entre $6 000 a $10 000 por mês'
          },
          {
            value: 2,
            label: 'Entre $4 000 a $6 000 por mês'
          },
          {
            value: 3,
            label: 'Entre $2 000 a $4 000 por mês'
          },
          {
            value: 4,
            label: 'Abaixo de $2 000 por mês',
        }
      ]
    },
    {
      /* Pergunta 10  51 duvida neste fluxo pois não pergunta ha quanto tempo vende ou se vende em outros canais*/ 
      name:'question15text',
      placeHolder: 'Pergunta final',
      label: 'Em poucas linhas, descreva mais sobre como eu posso te ajudar',
      tooltip: 'Vai ser um prazer acompanhar o sucesso do seu produto na plataforma Amazon!',
      type: TYPE.TEXT,
      required: true
    },
    {
      name:'email_form',
      placeHolder: 'Digite seu melhor email',
      label: 'Preencha seus dados de contato e clique no botão ENVIAR !',
      tooltip: 'Obrigado ! Não esqueça de clicar em enviar formulário para que possamos entrar em contato com você.',
      type: TYPE.TEXT,
      required: true,
    }
  ]
}

dataForma2NaoEUSubFluxo2: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 2',
  questions: [
    {
    /* Pergunta 7 */
    name:'question10radio',
    label: 'Venda do produto em outros canais',
    descricao: 'Há quanto tempo você vende este produto?',
    tooltip: 'Fique tranquilo, suas informações não serão divulgadas.',
    type: TYPE.RADIO,
    required: true,
    options: [
      {
        value: 0,
        label: 'Menos de 6 meses'
      },
      {
        value: 1,
        label: 'Entre 6 meses e 1 ano'
      },
      {
        value: 2,
        label: 'Entre 1 ano e 3 anos'
      },
      {
        value: 3,
        label: 'Mais de 3 anos',
      }
    ]
  },
  {
    /* Pergunta 8 */
    name:'question11radio',
    label: 'Faturamento mensal',
    descricao: 'Qual o seu faturamento mensal em dólares americanos?',
    tooltip: 'Esses dados são usados como fonte de pesquisa interna e não interferem no sucesso ou não do seu produto',
    type: TYPE.RADIO,
    required: true,
    options: [
      {
        value: 0,
        label: '0 até $1 000 por mês'
      },
      {
        value: 1,
        label: '$1 000 até $5 000 por mês'
      },
      {
        value: 2,
        label: '$5 000 até $20 000 por mês'
      },
      {
        value: 3,
        label: '$20 000 até $50 000 por mês',
      },
      {
        value: 4,
        label: 'Acima de $50 000 por mês',
      }
    ]
  },
  {
    /* Pergunta 9 */ 
    name:'question13radio',
    label: 'Disponibilidade do produto para venda',
    descricao: 'Quando você gostaria de introduzir o seu produto na Amazon EUA?',
    tooltip: 'Cerca de 15% dos nossos clientes começaram a vender na Amazon sem ter idéia alguma de por começar. Se este for o seu caso, fique tranquilo, nós vamos te ajudar :)',
    type: TYPE.RADIO,
    required: true,
    options: [
      {
        value: 0,
        label: 'Imediato, já estamos prontos para vender'
      },
      {
        value: 1,
        label: 'Em até 30 dias'
      },
      {
        value: 2,
        label: 'Acima de 30 dias, pois tenho algumas decisões a tomar'
      },
      {
        value: 3,
        label: 'Ainda não sei',
      }
    ]
  },
  {
    /* Pergunta 51 nova */
    name:'question51radio0',
    // nameSecudary: 'question51text',
    label: 'Investimento na Amazon',
    descricao: 'Quanto você pretende investir (em dólares) na Amazon?',
    tooltip: 'Quanto maior o seu investimento na plataforma, maiores as suas chances de retorno',
    type: TYPE.RADIO,
    required: false,
    options: [
      {
        value: 0,
        label: 'Acima de $10 000 por mês'
      },
      {
        value: 1,
        label: 'Entre $6 000 a $10 000 por mês'
      },
      {
        value: 2,
        label: 'Entre $4 000 a $6 000 por mês'
      },
      {
        value: 3,
        label: 'Entre $2 000 a $4 000 por mês'
      },
      {
        value: 4,
        label: 'Abaixo de $2 000 por mês',
    }
    ]
  },
  {
    /* Pergunta 11  */ 
    name:'question15text',
    placeHolder: 'Pergunta final',
    label: 'Em poucas linhas, descreva qual tipo de ajuda você está buscando?',
    tooltip: 'Use esse espaço para dizer como podemos te ajudar!',
    type: TYPE.TEXT,
    required: true
  },
  {
    name:'email_form',
    placeHolder: 'Digite seu melhor email',
    label: 'Preencha seus dados de contato e clique no botão ENVIAR !',
    tooltip: 'Obrigado ! Não esqueça de clicar em enviar formulário para que possamos entrar em contato com você.',
    type: TYPE.TEXT,
    required: true,
  }
]
}

dataForm2NaoBrazil: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 1',
  questions: [
    {
      /* Pergunta 2 */
      name:'question3radio',
      answerIndex: 0,
      label: ', considerando a sua disponibilidade de tempo agora, selecione uma das opções abaixo:',
      descricao: '',
      type: TYPE.RADIO,
      tooltip: 'Sugerimos que selecione a versão completa do nosso formulário de atendimento.',
      required: true,
      options: [
        {
          value: 0,
          label: 'Tenho 40 segundos e vou preencher o formulário completo porque entendo o tamanho e importância da Amazon para o meu produto.'
        },
        {
          value: 1,
          label: 'Tenho apenas 20 segundos e quero preencher o formulário rápido porque a Amazon não é prioridade para o meu negócio.'
        }
      ]
    },
    {
    /* Pergunta 3 */
      name:'question5radio',
      nameSecudary: 'question4text',
      label: 'Sobre a sua marca',
      // descricao: '',
      type: TYPE.MULTIPLES_INPUTS,
      required: false,
      options: [
        {
          value: '',
          required: false,
          tooltip: 'Fique tranquilo, nós não compartilhamos nenhuma destas informações.',
          placeHolder: 'Qual o nome da sua marca?'
        }
      ]
    },
    {
      /* Pergunta 51 */
      name:'question3TextDescption',
      placeHolder: 'Descrição do produto',
      label: 'Descreva o seu produto em poucas palavras',
      tooltip: 'Fique tranquilo, as informações do seu produto estão seguras e não serão divulgadas',
      type: TYPE.TEXT,
      required: true
    },
    {
     /* Pergunta 5 */
      name:'question7radio',
      nameSecudary: 'question7text',
      label: 'Em qual segmento seu produto se encaixa?',
      descricao: '',
      tooltip: 'Na dúvida, fique a vontade para especificar em qual categoria você enquadra o seu produto',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Beleza & cuidados pessoais'
        },
        {
          value: 1,
          label: 'Vitaminas & suplementos'
        },
        {
          value: 2,
          label: 'Acessórios para atividades esportivas'
        },
        {
          value: 3,
          label: 'Casa e cozinha',
        },
        {
          value: 4,
          label: 'Alimentos em geral',
        },
        {
          value: 5,
          placeHolder: 'Outros',
          required: true,
          label: 'Outros (especifique abaixo)',
        }
      ]
    },
    {
        /* Pergunta 6 */
      name:'question8radio',
      // nameSecudary: 'question8text',
      label: 'Quantidade de produtos na Amazon',
      descricao: 'Quantos produtos você deseja listar?',
      tooltip: 'Essa informação é importante para sabermos a variedade do seu produto.',
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: '1'
        },
        {
          value: 1,
          label: '2'
        },
        {
          value: 2,
          label: '3'
        },
        {
          value: 3,
          label: '4 ou mais',
        }
      ]
    },
    {
         /* Pergunta 7 */
      name:'question9radio',
      label: 'Canais de venda do seu produto',
      descricao: 'Onde mais você vende este produto?',
      tooltip: 'Fique a vontade para marcar quantas opções quiser',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
            value: 0,
          label: 'Não vendo em outros canais'
        },
        {
          value: 1,
          label: 'Vendo em loja física'
        },
        {
          value: 2,
          label: 'Vendo no meu site'
        },
        {
          value: 3,
          label: 'Vendo nas redes sociais'
        },
        {
          value: 4,
          label: 'Vendo em outros marketplaces'
        },
        {
          value: 5,
          label: 'Em outros canais',
        }
      ]
    }
  ]
}

dataForm2NaoBrazilFluxo1: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 1',
  questions: [
    {
      /* Pergunta 10 */ 
      name:'question12radio',
      label: 'Situação cadastral',
      descricao: 'Você já tem empresa aberta para vender nos EUA?',
      tooltip: 'Poucas pessoas sabem, mas não existe obrigatoriedade de abertura de empresa para vender na Amazon EUA',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Sim'
        },
        {
          value: 1,
          label: 'Não'
        }
      ]
    },
    {
      /* Pergunta 8 */ 
      name:'question13radio',
      label: 'Disponibilidade do seu produto na Amazon',
      descricao: 'Quando você deseja disponibilizar o seu produto na plataforma da Amazon EUA?',
      tooltip: 'Cerca de 15% dos nossos clientes começaram a vender na Amazon sem ter idéia alguma de por começar. Se este for o seu caso, fique tranquilo, nós vamos te ajudar :)',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Imediato, todos os processo de exportação já foram resolvidos'
        },
        {
          value: 1,
          label: 'Em até 30 dias'
        },
        {
          value: 2,
          label: 'Acima de 30 dias, pois tenho decisões a tomar antes'
        },
        {
          value: 3,
          label: 'Não sei, preciso de ajuda com a exportação para os EUA'
        },
        {
          value: 4,
          label: 'Ainda não decidi',
        }
      ]
    },
    {
      /* Pergunta 51 nova */
      name:'question51radio5',
      // nameSecudary: 'question51text',
      label: 'Investimento na Amazon',
      descricao: 'Quanto você pretende investir mensalmente (em dolares americanos) na Amazon EUA?',
      tooltip: 'Quanto maior o seu investimento na plataforma, maiores as suas possibilidades de retorno',
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: 'Acima de $10 000 por mês'
        },
        {
          value: 1,
          label: 'Entre $6 000 a $10 000 por mês'
        },
        {
          value: 2,
          label: 'Entre $4 000 a $6 000 por mês'
        },
        {
          value: 3,
          label: 'Entre $2 000 a $4 000 por mês'
        },
        {
          value: 4,
          label: 'Abaixo de $2 000 por mês',
        }
      ]
    },
    {
      /* Pergunta 10  */ 
      name:'question15text',
      placeHolder: 'Pergunta final',
      label: 'Em poucas linhas, descreva qual tipo de ajuda você está buscando?',
      tooltip: 'Use esse espaço para dizer como podemos te ajudar !',
      type: TYPE.TEXT,
      required: true
    },
    {
      name:'email_form',
      placeHolder: 'Digite seu melhor email',
      label: 'Preencha seus dados de contato e clique no botão ENVIAR !',
      tooltip: 'Obrigado ! Não esqueça de clicar em enviar formulário para que possamos entrar em contato com você.',
      type: TYPE.TEXT,
      required: true,
    }
  ]
}

dataForm2NaoBrazilFluxo2: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 1',
  questions: [
    {
      /* Pergunta 10 */ 
      name:'question12radio',
      label: 'Situação cadastral',
      descricao: 'Você já tem empresa aberta para vender nos EUA?',
      tooltip: 'Poucas pessoas sabem, mas não existe obrigatoriedade de abertura de empresa para vender na Amazon EUA',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Sim'
        },
        {
          value: 1,
          label: 'Não'
        }
      ]
    },
    {
      /* Pergunta 8 */
      name:'question10radio',
      label: 'Tempo do produto no mercado',
      descricao: 'Há quanto tempo você vende este produto?',
      tooltip: 'Fique tranquilo, suas informações não serão divulgadas.',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Menos de 6 meses'
        },
        {
          value: 1,
          label: 'Entre 6 meses e 1 ano'
        },
        {
          value: 2,
          label: 'Entre 1 ano e 3 anos'
        },
        {
          value: 3,
          label: 'Mais de 3 anos',
        }
      ]
    },
    {
      /* Pergunta 9 */
      name:'question11radio',
      label: 'Faturamento mensal',
      descricao: 'Qual o seu faturamento mensal em dólares americanos?',
      tooltip: 'Esses dados são usados como fonte de pesquisa interna e não interferem no sucesso ou não do seu produto',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: '0 até $1 000 por mês'
        },
        {
          value: 1,
          label: '$1 000 até $5 000 por mês'
        },
        {
          value: 2,
          label: '$5 000 até $20 000 por mês'
        },
        {
          value: 3,
          label: '$20 000 até $50 000 por mês',
        },
        {
          value: 4,
          label: 'Acima de $50 000 por mês',
        }
      ]
    },
    {
      /* Pergunta 51 nova */
      name:'question51radio8',
      // nameSecudary: 'question51text',
      label: 'Investimento na Amazon',
      descricao: 'Quanto você pretende investir (em dólares) na Amazon?',
      tooltip: 'Quanto maior o seu investimento na plataforma, maiores as suas chances de retorno',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Acima de $10 000 por mês'
        },
        {
          value: 1,
          label: 'Entre $6 000 a $10 000 por mês'
        },
        {
          value: 2,
          label: 'Entre $4 000 a $6 000 por mês'
        },
        {
          value: 3,
          label: 'Entre $2 000 a $4 000 por mês'
        },
        {
          value: 4,
          label: 'Abaixo de $2 000 por mês',
        }
      ]
    },
    {
      /* Pergunta 11 */ 
      name:'question13radio',
      label: 'Implantação do seu produto',
      descricao: 'Quando você gostaria de introduzir o seu produto na Amazon EUA?',
      tooltip: 'Cerca de 15% dos nossos clientes começaram a vender na Amazon sem ter idéia alguma de 		por começar. Se este for o seu caso, fique tranquilo, nós vamos te ajudar :)',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Imediato, todos os processos de exportação já foram resolvidos'
        },
        {
          value: 1,
          label: 'Em até 30 dias'
        },
        {
          value: 2,
          label: 'Acima de 30 dias'
        },
        {
          value: 3,
          label: 'Não sei, preciso de ajuda com exportação para os EUA',
        },
        {
          value: 4,
          label: 'Ainda não decidi',
        }
      ]
    },
    {
      /* Pergunta 11  */ 
      name:'question15text',
      placeHolder: 'Pergunta final',
      label: 'Em poucas linhas, descreva qual tipo de ajuda você está buscando para ter o seu produto na Amazon EUA?',
      tooltip: 'Use esse espaço para dizer como podemos te ajudar!',
      type: TYPE.TEXT,
      required: true
    },
    {
      name:'email_form',
      placeHolder: 'Digite seu melhor email',
      label: 'Preencha seus dados de contato e clique no botão ENVIAR !',
      tooltip: 'Obrigado ! Não esqueça de clicar em enviar formulário para que possamos entrar em contato com você.',
      type: TYPE.TEXT,
      required: true,
    }
  ]
}

dataForm2NaoOutros: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 1',
  questions: [
    {
      /* Pergunta 2 */
      name:'question3radio',
      answerIndex: 0,
      label: ', considerando a sua disponibilidade de tempo agora, selecione uma das opções abaixo:',
      descricao: '',
      type: TYPE.RADIO,
      tooltip: 'Sugerimos que selecione a versão completa do nosso formulário de atendimento.',
      required: true,
      options: [
        {
          value: 0,
          label: 'Tenho 40 segundos e vou preencher o formulário completo porque entendo o tamanho e importância da Amazon para o meu produto.'
        },
        {
          value: 1,
          label: 'Tenho apenas 20 segundos e quero preencher o formulário rápido porque a Amazon não é prioridade para o meu negócio.'
        }
      ]
    },
    {
      /* Pergunta 3 */
      name:'question5radio',
      nameSecudary: 'question4text',
      label: 'Sobre a sua marca',
      // descricao: '',
      type: TYPE.MULTIPLES_INPUTS,
      required: false,
      options: [
        {
          value: '',
          required: false,
          tooltip: 'Fique tranquilo, nós não compartilhamos nenhuma destas informações.',
          placeHolder: 'Qual o nome da sua marca?'
        },
      ]
    },
    {
      /* Pergunta 51 */
      name:'question3TextDescription',
      placeHolder: 'Descrição do produto',
      label: 'Descreva o seu produto em poucas palavras',
      tooltip: 'Fique tranquilo, as informações do seu produto estão seguras e não serão divulgadas',
      type: TYPE.TEXT,
      required: true
    },
    {
     /* Pergunta 5 */
      name:'question7radio',
      nameSecudary: 'question7text',
      label: 'Em qual segmento seu produto se encaixa?',
      descricao: '',
      tooltip: 'Na dúvida, fique a vontade para especificar em qual categoria você enquadra o seu produto',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Beleza & cuidados pessoais'
        },
        {
          value: 1,
          label: 'Vitaminas & suplementos'
        },
        {
          value: 2,
          label: 'Acessórios para atividades esportivas'
        },
        {
          value: 3,
          label: 'Casa e cozinha',
        },
        {
          value: 4,
          label: 'Alimentos em geral',
        },
        {
          value: 5,
          placeHolder: 'Outros',
          required: true,
          label: 'Outros (especifique abaixo)',
        }
      ]
    },
    {
      /* Pergunta 6 */
      name:'question8radio',
      // nameSecudary: 'question8text',
      label: 'O seu produto na Amazon',
      descricao: 'Quantos produtos você deseja listar?',
      tooltip:'Esta informação é importante para sabermos da variedade do seu produto',
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: '1'
        },
        {
          value: 1,
          label: '2'
        },
        {
          value: 2,
          label: '3'
        },
        {
          value: 3,
          label: '4 ou mais',
        }
      ]
    },
    {
      /* Pergunta 7 */
      name:'question9radio',
      label: 'Canais de venda do seu produto',
      descricao: 'Selecione os canais de vendas atuais do seu produto',
      tooltip: 'Fique a vontade para marcar quantas opções quiser',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Não vendo em outros canais'
        },
        {
          value: 1,
          label: 'Vendo em loja física'
        },
        {
          value: 2,
          label: 'Vendo no meu site'
        },
        {
          value: 3,
          label: 'Vendo nas redes sociais'
        },
        {
          value: 4,
          label: 'Vendo em outros marketplaces'
        },
        {
          value: 5,
          label: 'Em outros canais',
        }
      ]
    }
  ]
}

dataForm2NaoOutrosFluxo1: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 1',
  questions: [
    {
      /* Pergunta 10 */ 
      name:'question12radio',
      label: 'Situação cadastral',
      descricao: 'Você já tem empresa aberta para vender nos EUA?',
      tooltip: 'Poucas pessoas sabem, mas não existe obrigatoriedade de abertura de empresa para vender na Amazon EUA',
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: 'Sim'
        },
        {
          value: 1,
          label: 'Não'
        }
      ]
    },
    {
      /* Pergunta 11 */ 
      name:'question13radio',
      label: 'Implantação',
      descricao: 'Quando você gostaria de introduzir o seu produto na Amazon EUA?',
      tooltip: 'Cerca de 15% dos nossos clientes começaram a vender na Amazon sem ter idéia alguma de por começar. Se este for o seu caso, fique tranquilo, nós vamos te ajudar :)',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: 'Imediato, todos os processos de exportação já foram resolvidos'
        },
        {
          value: 1,
          label: 'Em até 30 dias'
        },
        {
          value: 2,
          label: 'Acima de 30 dias, pois tenho decisões a tomar antes'
        },
        {
          value: 3,
          label: 'Não sei, pois preciso de ajuda com o processo de exportação para os EUA',
        },
        {
          value: 4,
          label: 'Ainda não decidi',
        }
      ]
    },
    {
      /* Pergunta 51 nova */
      name:'question51radio11',
      // nameSecudary: 'question51text',
      label: 'Investimento na Amazon',
      descricao: 'Quanto você pretende investir (em dólares) na Amazon?',
      tooltip: 'Quanto maior o seu investimento na plataforma, maiores as suas chances de retorno',
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: 'Acima de $10 000 por mês'
        },
        {
          value: 1,
          label: 'Entre $6 000 a $10 000 por mês'
        },
        {
          value: 2,
          label: 'Entre $4 000 a $6 000 por mês'
        },
        {
          value: 3,
          label: 'Entre $2 000 a $4 000 por mês'
        },
        {
          value: 4,
          label: 'Abaixo de $2 000 por mês',
        }
      ]
    },
    {
      /* Pergunta 13  */ 
      name:'question15text',
      placeHolder: 'Pergunta final',
       label: 'Em poucas linhas, descreva mais sobre como eu posso te ajudar',
      tooltip: 'Eu quero te ajudar nesta decisão importante de vender na Amazon dos EUA!',
      type: TYPE.TEXT,
      required: true
    },
    {
      name:'email_form',
      placeHolder: 'Digite seu melhor email',
      label: 'Preencha seus dados de contato e clique no botão ENVIAR !',
      tooltip: 'Obrigado ! Não esqueça de clicar em enviar formulário para que possamos entrar em contato com você.',
      type: TYPE.TEXT,
      required: true,
    }
  ]
}

dataForm2NaoOutrosFluxo2: Survey = {
  label: '',
  english: false,
  idForm: 3,
  formName: 'Formulario 1',
  questions: [
    {
      /* Pergunta 8 */
      name:'question10radio',
      label: 'Tempo do produto no mercado',
      descricao: 'Há quanto tempo você vende este produto?',
      tooltip: 'Fique tranquilo, suas informações não serão divulgadas.',
      type: TYPE.RADIO,
      disabled: true,
      required: true,
      options: [
        {
          value: 0,
          label: 'Menos de 6 meses'
        },
        {
          value: 1,
          label: 'Entre 6 meses e 1 ano'
        },
        {
          value: 2,
          label: 'Entre 1 ano e 3 anos'
        },
        {
          value: 3,
          label: 'Mais de 3 anos',
        }
      ]
    },
    {
      /* Pergunta 9 */
      name:'question11radio',
      label: 'Faturamento mensal atual',
      descricao: 'Qual o seu faturamento mensal em dólares americanos?',
      tooltip: 'Esses dados são usados como fonte de pesquisa interna e não interferem no sucesso ou não do seu produto',
      type: TYPE.RADIO,
      required: true,
      options: [
        {
          value: 0,
          label: '0 até $1 000 por mês'
        },
        {
          value: 1,
          label: '$1 000 até $5 000 por mês'
        },
        {
          value: 2,
          label: '$5 000 até $20 000 por mês'
        },
        {
          value: 3,
          label: '$20 000 até $50 000 por mês',
        },
        {
          value: 4,
          label: 'Acima de $50 000 por mês',
        }
      ]
    },
    {
    /* Pergunta 10 */
      name:'question12radio',
      label: 'Pessoa jurídica',
      descricao: 'Você já tem empresa aberta nos EUA?',
      tooltip: 'Poucas pessoas sabem, mas não existe obrigatoriedade de abertura de empresa para vender na Amazon EUA',
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: 'Sim'
        },
        {
          value: 1,
          label: 'Não'
        }
      ]
    },
    {
      /* Pergunta 11 */ 
      name:'question13radio',
      label: 'Implantação na Amazon',
      descricao: 'Quando você gostaria de introduzir o seu produto na Amazon EUA?',
      tooltip: 'Cerca de 15% dos nossos clientes começaram a vender na Amazon sem ter idéia alguma de por começar. Se este for o seu caso, fique tranquilo, nós vamos te ajudar :)',
      type: TYPE.RADIO,
      required: true,
       options: [
        {
          value: 0,
          label: 'Imediato, todos os processos de exportação já foram resolvidos'
        },
        {
          value: 1,
          label: 'Em até 30 dias'
        },
        {
          value: 2,
          label: 'Acima de 30 dias'
        },
        {
          value: 3,
          label: 'Não sei, preciso de ajuda com exportação para os EUA',
        },
        {
          value: 4,
          label: 'Ainda não decidi',
        }
      ]
    },
    {
      /* Pergunta 51 nova */
      name:'question51radio12',
      // nameSecudary: 'question51text',
      label: 'Investimento na Amazon',
      descricao: 'Quanto você pretende investir (em dólares) na Amazon?',
      tooltip: 'Quanto maior o seu investimento na plataforma, maiores as suas chances de retorno',
      type: TYPE.RADIO,
      required: false,
      options: [
        {
          value: 0,
          label: 'Acima de $10 000 por mês'
        },
        {
          value: 1,
          label: 'Entre $6 000 a $10 000 por mês'
        },
        {
          value: 2,
          label: 'Entre $4 000 a $6 000 por mês'
        },
        {
          value: 3,
          label: 'Entre $2 000 a $4 000 por mês'
        },
        {
          value: 4,
          label: 'Abaixo de $2 000 por mês',
        }
      ]
    },
    {
      /* Pergunta 13  */ 
      name:'question15text',
       placeHolder: 'Pergunta final',
       label: 'Em poucas linhas, descreva mais sobre como eu posso te ajudar',
      tooltip: 'Eu quero te ajudar nesta decisão importante de vender na Amazon dos EUA!',
      type: TYPE.TEXT,
      required: true
    },
    {
      name:'email_form',
      placeHolder: 'Digite seu melhor email',
      label: 'Preencha seus dados de contato e clique no botão ENVIAR !',
      tooltip: 'Obrigado ! Não esqueça de clicar em enviar formulário para que possamos entrar em contato com você.',
      type: TYPE.TEXT,
      required: true,
    }
  ]
}

// Fim - Formulario 2


}
