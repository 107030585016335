import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Survey, TYPE } from './model';
import { SurveyFormService } from "../services/surveyform.services";


@Component({
  selector: 'app-survey-form',
  templateUrl: './surveyform.component.html',
  styleUrls: ['./surveyform.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1
      })),
      state('closed', style({
        display: 'none',
        opacity: 0
      })),
      // transition('open => closed', [
      //   animate('200ms ease-out')
      // ]),
      // transition('closed => open', [
      //   animate('200ms 200ms ease-in')
      // ]),
    ]),
  ]
})
export class SurveyFormComponent implements OnInit {

  letter: any[] = [{ name: 'A' },{ name: 'B' },{ name: 'C' },{ name: 'D' },{ name: 'E' },{ name: 'F' },{ name: 'G' },{ name: 'H' },{ name: 'I' },{ name: 'J' },{ name: 'K' },{ name: 'L' },{ name: 'M' },{ name: 'N' },{ name: 'A' },{ name: 'O' },{ name: 'P' },{ name: 'Q' },{ name: 'R' },{ name: 'S' },{ name: 'T' },{ name: 'U' },{ name: 'V' },{ name: 'W' },{ name: 'X' },{ name: 'Y' },{ name: 'Z' }];

  @Input() set data(s: Survey) {this.createForm(s);}
  @Output() surveySubmit = new EventEmitter();
  @Output() voltar = new EventEmitter();
  @Output() removeIndexArray = new EventEmitter();
  _data: Survey;
  fg: FormGroup = new FormGroup({});
  active = 0;
  @Input() disabled: boolean = true;
  @Input() fgAlternative: any;
  @Input() isSubmit: boolean = false;
  @Input() loading: boolean = false;

  ngOnInit() { }

  createForm(s: Survey) {
    this._data = s;
    s.questions.forEach(q => {
      const validator = q.required ? [Validators.required] : [];

      switch (q.type) {
        case TYPE.TEXT:
        case TYPE.RADIO_IMAGE:
        case TYPE.RADIO: {
          this.fg.addControl(q.name, new FormControl(q.value, validator));
          this.fg.addControl(q.nameSecudary, new FormControl(q.valueSecudary, validator));
          break;
        }

        case TYPE.CHECKBOX: {
          let value = q.value || '';
          this.fg.addControl(
            q.name,
            new FormArray(q.options.map(x => new FormControl(value.includes(x.value))), validator)
          );
          break;
        }

        case TYPE.MULTIPLES_INPUTS: {
          let value = q.value || '';
          this.fg.addControl(
            q.name,
            new FormArray(q.options.map(x => new FormControl('')), validator)
          );
          break;
        }
      }
    });

    console.log(this.fg);
  }

  setActive(index: number) {
    if (this.active < this._data.questions.length) {
      const activeControl = this.fg.controls[this._data.questions[this.active].name];
      activeControl.markAsTouched();
      this._data.questions[this.active].value = this.fg.controls[this._data.questions[this.active].name].value;
    }

    this.active = index;
  }

  validaCampos(status: any, dta: any) {
    if (status === 'INVALID') {
      var i;
      for (i = 0; i < dta.options.length; i++) {
        if (dta.nameSecudary != null && this.fg.controls[dta.nameSecudary].value != null) {
          if (dta.options[i].required == true && this.fg.controls[dta.nameSecudary].value == "" || this.fg.controls[dta.nameSecudary].value.length == 0) {
            return true;
            break;
          }
        } else {
          return true;
          break;
        }
      }
    }
    return false;
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      // alert('you just pressed the enter key');
      // this.setActive(this.active + 1);
    }
  }

  enviar() {
    this.surveySubmit.emit();
  }

  voltarEvent() {
    this.voltar.emit();
  }
  
  removeIndexArrayEvent() {
    this.removeIndexArray.emit();
  }

  onSubmit() {
    console.log(this.fg);
  }
}
